import { reactive, toRefs, onMounted, watch, computed } from 'vue'
import { notification } from 'ant-design-vue'
import store from '@/store'
import route from '@/router'
import debounce from 'lodash/debounce'
import { getSeller, getReturList, updateReturStatus } from '@/api/channels/distributor'
import { getWarehouseList } from '@/api/warehouse'

export default () => {
  let routeName = route.app._route.name
  const state_ = reactive({
    dataListRetur: [],
    warehouseData: [],
    businessId: '',
    selectedWarehouse: '',
    pagination: {
      size: 10,
      total_items: 0,
    },
    pages: 1,
    loading: false,
    showModal: false,
  })

  onMounted(() => {
    getBusinessId()
    getWarehouse()
    getListRetur()
  })

  watch(
    () => route.app._route.query,
    () => {
      if (route.app._route.name == routeName) {
        getListRetur()
      }
    },
  )
  
  watch(
    () => state_.selectedWarehouse,
    () => {
        getListRetur()
    },
  )

  const page = computed(() => {
    return +route.app._route.query.page || 1
  })

  const handleShowModal = (param) => {
    state_.showModal = param
  }

  const getBusinessId = async () => {
    state_.businessId = store.state.user.businessList?.[0]?.business_id
    return await store.state.user.businessList?.[0]?.business_id
  }

  const getWarehouse = async () => {
    if (store.state.user.restriction_base.toLowerCase() == 'business') {
      await getWarehouseList({ business_id: route.app._route.query.business_id })
      .then(({ data }) => state_.warehouseData = data.data)
      .catch(err => console.error(err))
    }
  }

const handleConfirm = async (returId) => {
    const businessId = route.app._route.query.business_id || (await getBusinessId())
    state_.loading = true

    await updateReturStatus({
      business_id: businessId,
      channel_id: route.app._route.params.id,
      return_id: returId,
      status: 'ACKNOWLEDGED',
      data: undefined,
    })
    .then(({ data }) => {
      if (data.message_code === "ALL.ALL.OK") {
        notification.success({
          message: 'Berhasil',
          description: 'Data Berhasil Diperbaharui',
        })
        handleShowModal(false)
      } else {
        notification.error({
          message: 'Gagal',
          description: data.message,
        })
      }
    })
    .catch(err => {
      console.error(err)
      notification.error({
        message: 'Gagal',
        description: err?.response?.data?.message,
      })
    })
    .finally(() => state_.loading = false)
  }

const handleProcess = async (returId, payload) => {
    state_.loading = true
    const businessId = route.app._route.query.business_id || (await getBusinessId())

    await updateReturStatus({
      business_id: businessId,
      channel_id: route.app._route.params.id,
      return_id: returId,
      status: 'ACCEPTED',
      data: payload,
    })
    .then(({ data }) => {
      if (data.message_code === "ALL.ALL.OK") {
        notification.success({
          message: 'Berhasil',
          description: 'Data Berhasil Diperbaharui',
        })
        handleShowModal(false)
      } else {
        notification.error({
          message: 'Gagal',
          description: data.message,
        })
      }
    })
    .catch(err => {
      console.error(err)
      notification.error({
        message: 'Gagal',
        description: err?.response?.data?.message,
      })
    })
    .finally(() => state_.loading = false)
  }

const handleReject = async (returId, payload) => {
    state_.loading = true
    const businessId = route.app._route.query.business_id || (await getBusinessId())

    await updateReturStatus({
      business_id: businessId,
      channel_id: route.app._route.params.id,
      return_id: returId,
      status: 'REJECTED',
      data: { notes_header: payload },
    })
    .then(({ data }) => {
      if (data.message_code === "ALL.ALL.OK") {
        notification.success({
          message: 'Berhasil',
          description: 'Data Berhasil Diperbaharui',
        })
        handleShowModal(false)
      } else {
        notification.error({
          message: 'Gagal',
          description: data.message,
        })
      }
    })
    .catch(err => {
      console.error(err)
      notification.error({
        message: 'Gagal',
        description: err?.response?.data?.message,
      })
    })
    .finally(() => state_.loading = false)
  }

  const getListRetur = debounce(async () => {
    state_.loading = true
    routeName = route.app._route.name
    let sellerId
    const statusTemp = route.app._route.name  === 'retur-channel.retur-list' ? 'ACCEPTED,REJECTED' : 'REQUESTED,ACKNOWLEDGED'
    const businessId = route.app._route.query.business_id || (await getBusinessId())

    await getSeller({
      businessId,
      params: { warehouse_id: route.app._route.query.warehouse_id || state_.selectedWarehouse },
    })
    .then(async ({ data: { data: response } }) => {
      sellerId = response?.[0]?.seller_id

      await getReturList({
        business_id: businessId,
        channel_id: route.app._route.params.id,
        params: {
          limit: state_.pagination.size,
          page: page.value,
          seller_id: sellerId,
          search: route.app._route.query.search || undefined,
          status: route.app._route.query.status || statusTemp,
        },
      })
      .then(({ data: { data: response } }) => {
        state_.dataListRetur = response?.contents || []
        state_.pagination.total_items = response?.total_elements || 0
      })
      .catch(err => {
        state_.dataPayment = []
        state_.dataListRetur = []
        state_.pagination.total_items = 0
        console.error(err)
      })
      .finally(() => state_.loading = false)
    })
  }, 500)

  const changePage = value => {
    route.push({
      query: {
        ...route.app._route.query,
        page: value,
        search: undefined,
        sort: undefined,
      },
    })
  }

  const handleWarehouse = e => {
    state_.selectedWarehouse = e
  }

  return {
    ...toRefs(state_),
    changePage,
    handleWarehouse,
    page,
    handleConfirm,
    handleShowModal,
    handleProcess,
    handleReject,
    getListRetur,
  }
}
