<template>
  <div class="pbz-font d-flex flex-column justify-content-center">
    <div class="d-flex m-3">
      <h3>{{ $route.name === 'retur-channel.retur-list' ? 'List Retur' : 'List Pengajuan Retur' }}</h3>
    </div>

    <div
      v-if="$store.state.user.restriction_base?.toLowerCase() === 'business' && $store.state.user.role_name?.toLowerCase() === 'principle'"
      style="width: 350px"
      class="mt-5"
    >
      <a-select
        placeholder="Pilih Warehouse"
        show-search
        style="width: 100%"
        option-filter-prop="label"
        @change="handleWarehouse"
      >
        <a-select-option
          v-for="(item, index) in warehouseData"
          :key="index"
          :value="item.id"
          :label="item.name"
        >
          <div>
            {{ item.name }}
          </div>
        </a-select-option>
      </a-select>
    </div>

    <div class="mt-4">
      <a-tabs
        v-if="$route.name === 'retur-channel.retur-list'"
        class="pca-tab-store"
        :default-active-key="$route.query.status || 'ALL'"
        @change="changeTab"
      >
        <a-tab-pane key="ACCEPTED,REJECTED" :tab="'Semua'" />
        <a-tab-pane key="ACCEPTED" :tab="'Selesai'" />
        <a-tab-pane key="REJECTED" :tab="'Ditolak'" />
      </a-tabs>
      <a-tabs v-else :default-active-key="$route.query.status || 'ALL'" class="pca-tab-store" @change="changeTab">
        <a-tab-pane key="REQUESTED,ACKNOWLEDGED" :tab="'Semua'" />
        <a-tab-pane key="REQUESTED" :tab="'Menunggu Konfirmasi'" />
        <a-tab-pane key="ACKNOWLEDGED" :tab="'Dalam Proses'" />
      </a-tabs>
    </div>

    <div class="retur-list-container d-flex flex-column justify-content-center">
      <div class="content">
        <!-- <a-row class="d-flex align-items-center">
          <a-col :span="12" class="d-flex">
            <div class="search-box mx-2">
              <a-input
                type="text"
                :placeholder="$t('userManage.search')"
              />
            </div>
            <SearchIcon class="mx-3" style="cursor: pointer" />
          </a-col>
        </a-row> -->
        <!-- LIST -->
        <a-table
          class="payment-list mt-3 mb-2 bg-white"
          :data-source="dataListRetur"
          :row-key="(record, index) => index"
          :columns="columns"
          :loading="loading"
          :pagination="false"
        >
          <template slot="return_number" slot-scope="text">
            <div
              class="text-blue-2"
              style="font-weight: bold"
            >
              {{ text }}
            </div>
          </template>
          <span slot="status" slot-scope="status">
            <a-tag
              :key="status"
              :color="status === 'DRAFT' ? 'orange' :
                status === 'REQUESTED' ? 'green' : 
                status === 'ACKNOWLEDGED' ? 'blue' : 
                status === 'ACCEPTED' ? 'cyan' :
                status === 'REJECTED' ? 'red' : 'pink'"
            >
              {{ getLabel(status) }}
            </a-tag>
          </span>
          <div slot="action" slot-scope="record" class="mr-3" @click="handleModal(true, record.status === 'REQUESTED' ? 'konfirmasi' : record.status === 'ACKNOWLEDGED' ? 'proses' : '', record)">
            <Eye class="mx-3" style="cursor: pointer" />
          </div>
          <!-- <div slot="expandedRowRender" slot-scope="record">
            <a-skeleton :loading="loading">
              <div class="d-flex">
                <a-col :span="12">
                  <h6>
                    Total Req : {{ record.total_amount_requested }}
                  </h6>
                  <div>
                    Produk : 3
                  </div>
                  <div>
                    Total Qty : 62
                  </div>
                  <div>
                    Total Pengembalian Dana Retur : Rp. 175.000
                  </div>
                </a-col>
                <a-col :span="10">
                  <h6>
                    Total Diproses : 
                  </h6>
                  <div>
                    Produk : 2
                  </div>
                  <div>
                    Total Qty : 7
                  </div>
                  <div>
                    Total Pengembalian Dana Retur : Rp. 20.000
                  </div>
                </a-col>
                <a-icon 
                  type="eye"
                  class="mr-3"
                  style="color: #E00; cursor: pointer;"
                  @click="handleModal(true)"
                />
              </div>
            </a-skeleton>
          </div> -->
        </a-table>
        <Pagination
          class="text-right"
          :total="pagination.total_items"
          :page-size="pagination.size"
          :total-length-per-page="dataListRetur?.length"
          :default-page="page"
          @changePage="changePage"
        />
      </div>
    </div>

    <ModalRetur 
      :show-modal="showModal"
      :type="type"
      :row="rowData"
      :loading="loading"
      @handleModal="handleModal"
      @handleConfirm="handleConfirm"
      @handleProcess="handleProcess"
      @handleReject="handleReject"
    />
  </div>
</template>

<script>
  import Eye from '@/components/Icons/Eye.vue'
  import ModalRetur from '@/views/retur-channel/list/ModalRetur.vue'
  import list from '@/composable/retur-channel/list'
  import Pagination from '@/components/Pagination/index.vue'

export default {
  name: 'ListRetur',
  components: {
    Eye,
    ModalRetur,
    Pagination,
  },
  setup() {
    const {
      dataListRetur,
      loading,
      warehouseData,
      pagination,
      page,
      showModal,
      changePage,
      handleWarehouse,
      getListRetur,
      handleShowModal,
      handleConfirm,
      handleProcess,
      handleReject,
    } = list()

    return {
      dataListRetur,
      loading,
      warehouseData,
      pagination,
      page,
      showModal,
      changePage,
      handleWarehouse,
      getListRetur,
      handleShowModal,
      handleConfirm,
      handleProcess,
      handleReject,
    }
  },
  data: function() {
    return {
      columns: [
        {
          title: 'No. Pengajuan Retur',
          dataIndex: 'return_number',
          key: 'return_number',
          ellipsis: true,
          width: '20%',
          class: 'text-center',
          scopedSlots: { customRender: 'return_number' },
        },
        {
          title: 'Distributor',
          dataIndex: 'seller_name',
          key: 'seller_name',
          width: '20%',
          class: 'text-center',
          ellipsis: true,
        },
        {
          title: 'Tanggal Pengajuan',
          dataIndex: 'requested_date',
          key: 'requested_date',
          class: 'text-center',
          customRender: value => {
            const time =
              value && this.$moment(value).format('DD MMM YYYY HH:mm')
            if (time) return time
            else return '-'
          },
        },
        {
          title: 'Pelanggaan',
          dataIndex: 'buyer_name',
          key: 'buyer_name',
          class: 'text-center',
          width: '20%',
          ellipsis: true,
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          class: 'text-center',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '',
          key: 'action',
          dataIndex: '',
          class: 'text-center',
          scopedSlots: { customRender: 'action' },
        },
      ],
      type: '',
      rowData: {},
    }
  },
  watch: {
    showModal: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value === false) {
          this.getListRetur()
        }
      },
    },
    '$route.name'() {
      this.getListRetur()
    },
  },
  methods: {
    handleModal(param, type, row) {
      this.handleShowModal(param)
      this.type = type
      this.rowData = row
    },
    changeTab(key) {
      let temp = { ...this.$route.query, status: key }
      this.$router.push({
        query: temp,
      })
    },
    getLabel(label) {
      if (label === 'DRAFT') {
        return 'Draft'
      } else if (label === 'REQUESTED') {
        return 'Menunggu Konfirmasi'
      } else if (label === 'ACKNOWLEDGED') {
        return 'Dalam Proses'
      } else if (label === 'ACCEPTED') {
        return 'Selesai'
      } else if (label === 'REJECTED') {
        return 'Ditolak'
      } else if (label === 'CANCELED') {
        return 'Dibatalkan'
      } 
    },
  },

}
</script>

<style lang="scss">
.retur-list-container {
  
  .content {
    width: 100%;
    padding: 1rem;
    // border: 1px solid #999;
    border-radius: 5px;
    border-top-left-radius: 0;

    .search-box {
    width: 228px;
    }
    .list {
    padding: 1rem;
    // border: 1px solid #999;
    border-radius: 5px;
    }
    .header-list {
      font-weight: 900;
      background: #E7ECEF;
      border-radius: 5px ;
      padding: 10px;
    }
    .data-list {
      padding: 5px 0;
      margin: 10px 0;
      background: #fff;
      border-radius: 5px ;
    }

    // ANT DESIGN
    .ant-collapse {
      border: none
    }
  }
}
.pca-tab-store {
  .ant-tabs-bar {
    // background-color: #fff;
    margin-bottom: 0;
    border-bottom-color: transparent;
  }
  .ant-tabs-nav .ant-tabs-tab:hover,
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #ff0a54;
  }
  .ant-tabs-ink-bar {
    background-color: #ff0a54;
  }
}
</style>