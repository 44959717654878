<template>
  <div>
    <a-modal
      :visible="showModal"
      :footer="false"
      class="modal-retur"
      @cancel="$emit('handleModal', false, type, {})"
    >
      <h3>
        Detail Pengajuan Retur
        <a-tag
          :key="dataRetur?.status"
          class="ml-3"
          :color="dataRetur?.status === 'DRAFT' ? 'orange' :
            dataRetur?.status === 'REQUESTED' ? 'green' : 
            dataRetur?.status === 'ACKNOWLEDGED' ? 'blue' : 
            dataRetur?.status === 'ACCEPTED' ? 'cyan' :
            dataRetur?.status === 'REJECTED' ? 'red' : 'pink'"
        >
          {{ getLabel(dataRetur?.status) }}
        </a-tag>
      </h3>
      <hr />
      <div class="d-flex mt-2">
        <a-col :span="3" style="color: #6C757D">
          No. Pengajuan Retur
        </a-col>
        <a-col :span="4" style="color: #212529">
          {{ dataRetur?.return_number }}
        </a-col>
        <a-col :span="3" style="color: #6C757D">
          Tanggal Pengajuan
        </a-col>
        <a-col :span="4" style="color: #212529">
          {{ $moment(dataRetur?.requested_date).format('DD MMM YYYY') }}
        </a-col>
        <a-col :span="3" style="color: #6C757D">
          Nama Pelanggan
        </a-col>
        <a-col :span="5" style="color: #212529">
          {{ dataRetur?.buyer_name }}
        </a-col>
      </div>

      <!-- DATA --> <!-- LIST -->
      <div class="list my-4">
        <a-table
          class="payment-list mt-3 mb-2 bg-white"
          :data-source="dataRetur?.return_items"
          :row-key="(record, index) => index"
          :columns="finalColumns()"
          :loading="loading"
          :pagination="false"
        >
          <template slot="req_qty_custom_title">
            <div class="title-tooltip">
              <span>
                Req QTY
              </span>
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <span>jumlah item yang di request</span>
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </div>
          </template>
          <template slot="app_qty_custom_title">
            <div class="title-tooltip">
              <span>
                App QTY
              </span>
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <span>jumlah item yang di approve</span>
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </div>
          </template>
          <template slot="requested_item_qty" slot-scope="text, record">
            <div>
              {{ text }} {{ record.item_uom }}
            </div>
          </template>
          <div slot="approved_item_qty" slot-scope="record">
            <a-col v-if="type === 'proses'" :span="16">
              <a-input
                v-model="record.approved_item_qty"
                type="number"
                :min="0"
                @input="handleChange($event, record.id)"
                @v-on:keypress="numbersOnly"
                @copy.prevent
                @paste.prevent
              />
              {{ record.item_uom }}
            </a-col>
            <span v-else> {{ record.approved_item_qty || '-' }} {{ record.approved_item_qty ? record.item_uom : '' }} </span>
          </div>
        </a-table>
        <div class="d-flex justify-content-end mt-5">
          <div class="mr-auto">
            <div class="d-flex mr-auto">
              <div class="mr-5" style="color: #6C757D">
                Total Nilai Retur
              </div>
              <div style="font-weight: bold">
                Rp. {{ toCurrency(returnTotal) }}
              </div>
            </div>
            <div class="d-flex mr-auto">
              <div class="mr-5" style="color: #6C757D">
                Total Koin Retur
              </div>
              <div style="font-weight: bold">
                {{ toCurrency(returnCoin) }}
              </div>
            </div>
          </div>
          <a-button v-if="type && dataRetur?.status !== 'ACCEPTED'" class="inbound__button mx-2 ml-auto" @click="showReject(true)">
            Tolak Pengajuan
          </a-button>
          <a-button v-if="type && dataRetur?.status !== 'ACCEPTED'" type="primary" :loading="loading" @click="showUpdate(true)">
            {{ type === 'konfirmasi' ? 'Terima' : 'Proses' }}
          </a-button>
        </div>
      </div>
    </a-modal>
    <a-modal
      :visible="visibleReject"
      :footer="false"
      :closable="false"
    >
      <div class="text-center">
        <Question class="mx-3 mb-3 mt-3" />
      </div>
      <h3 class="text-center mb-3">
        Tolak Pengajuan Retur
      </h3>
      <div class="mt-4">
        <span> Alasan Penolakan </span>
        <a-textarea
          v-model="isReason"
          placeholder="Masukan Alasan Penolakan"
          :auto-size="{ minRows: 5, maxRows: 5 }"
          :min="5"
          :max="75"
          @change="changeReason"
        />
        <div class="message-reason-retur" :style="`color: ${!(isReason.length >= 5 && isReason.length <= 75) ? 'red' : '#9e9e9e'}`">
          {{ isReason.length }} / 75 . Minimal 5 karakter dan maksimal 75 karakter
        </div>
      </div>
      <div class="text-center mt-5">
        <a-button class="inbound__button mx-2 ml-auto" @click="showReject(false)">
          Batalkan
        </a-button>
        <a-button
          type="primary"
          :loading="loading"
          :disabled="!(isReason.length >= 5 && isReason.length <= 75)"
          @click="handleRejectRetur"
        >
          Ya, Tolak
        </a-button>
      </div>
    </a-modal>
    <a-modal
      :visible="visibleUpdate"
      :footer="false"
      :closable="false"
    >
      <div class="text-center">
        <Question class="mx-3 mb-3 mt-3" />
      </div>
      <h3 class="text-center mb-3">
        Ubah Status Retur
      </h3>
      <div class="text-center mt-4">
        <span v-if="qtyTotalApprove > 0" style="color: #6C757D"> Apakah Anda yakin ingin menyelesaikan pengajuan retur ini? </span>
        <span v-else style="color: #6C757D"> Anda perlu malakukan tolak pengajuan jika tidak ada retur pada produk? </span>
      </div>
      <div class="text-center mt-5">
        <a-button class="inbound__button mx-2 ml-auto" @click="showUpdate(false)">
          Batalkan
        </a-button>
        <a-button v-if="qtyTotalApprove > 0" type="primary" :loading="loading" @click="handleRequest(dataRetur.id)">
          {{ dataRetur?.status !== 'REQUESTED' ? 'Ya, Terima' : 'Ya, Selesaikan ' }}
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Question from '@/components/Icons/Question.vue'

export default {
  name: 'ModalRetur',
  components: {
    Question,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  data: function() {
    return {
      columns: [
        {
          title: 'Nama Produk',
          dataIndex: 'catalog_title',
          key: 'catalog_title',
          class: 'text-center',
          width: '20%',
        },
        {
          dataIndex: 'requested_item_qty',
          key: 'requested_item_qty',
          slots: { title: 'req_qty_custom_title' },
          scopedSlots: { customRender: 'requested_item_qty' },
        },
        {
          dataIndex: '',
          key: 'approved_item_qty',
          ellipsis: true,
          slots: { title: 'app_qty_custom_title' },
          scopedSlots: { customRender: 'approved_item_qty' },
        },
        {
          title: 'Harga Retur',
          dataIndex: 'return_price',
          key: 'return_price',
          customRender: value => {
            const temp = value && this.toCurrency(value)
            if (temp) return 'Rp. ' + temp
            else return '-'
          },
        },
        {
          title: 'Total',
          dataIndex: '',
          key: 'subtotal_requested',
          customRender: value => {
            const temp = this.dataRetur?.status === 'ACKNOWLEDGED' || this.dataRetur?.status === 'ACCEPTED'
              ? this.toCurrency(value.approved_item_qty * value.return_price)
              : this.toCurrency(value.subtotal_requested)
            if (temp) return 'Rp. ' + temp
            else return '-'
          },
        },
        {
          title: 'Nilai Koin',
          dataIndex: '',
          key: 'return_coin',
          customRender: value => {
            const coinEstimate = value.return_coin / value.requested_item_qty
            const temp = this.dataRetur?.status === 'ACKNOWLEDGED'
              ? this.toCurrency(value.approved_item_qty * coinEstimate)
              : this.toCurrency(value.return_coin)
            if (temp) return temp
            else return '-'
          },
        },
        {
          title: 'Total Pengembalian Dana',
          dataIndex: 'subtotal_approved',
          key: 'subtotal_approved',
          ellipsis: true,
          customRender: value => {
            const temp = value && this.toCurrency(value)
            if (temp) return 'Rp. ' + temp
            else return '-'
          },
        },
        {
          title: 'Reason',
          dataIndex: 'request_reason',
          key: 'request_reason',
          customRender: value => {
            return value ? this.label[value] : '-'
          },
        },
      ],
      dataRetur: [],
      dataApprove : [],
      visibleReject: false,
      visibleUpdate: false,
      isReason: '',
      returnCoin: 0,
      returnTotal: 0,
      label: {
        damaged_product: 'Produk cacat atau rusak',
        expired_product: 'Produk mendekati expired',
        other_reason: 'Lain-lain',
      },
    }
  },
  watch: {
    row: {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.dataRetur = value
      },
    },
    showModal: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value === false) {
          this.visibleReject = value
          this.visibleUpdate = value
          this.isReason = ''
        }
      },
    },
    dataRetur: {
      deep: true,
      immediate: true,
      handler: function (value) {
        let returnCoin = 0
        let returnTotal = 0
        let qtyTotalApprove = 0
        if (value?.return_items && value.return_items.length > 0) {
          value.return_items.forEach(data => {
            const coinEstimate = data.return_coin / data.requested_item_qty
            if (this.dataRetur?.status === 'ACKNOWLEDGED') {
              returnCoin += data.approved_item_qty * coinEstimate
              returnTotal += data.approved_item_qty * data.return_price
              qtyTotalApprove += data.approved_item_qty
            } else {
              returnCoin += data.return_coin
              returnTotal += data?.subtotal_approved || data?.subtotal_requested
              qtyTotalApprove += data.requested_item_qty
            }
          })
        }

        this.returnCoin = returnCoin
        this.returnTotal = returnTotal
        this.qtyTotalApprove = qtyTotalApprove
      },
    },
  },
   methods: {
    finalColumns() {
      let columns = this.columns
      let finalColumns = []
      columns.forEach((data) => {
        let isPush = true
        if (this.dataRetur?.status === 'DRAFT' || this.dataRetur?.status === 'REQUESTED' || this.dataRetur?.status === 'REJECTED' || this.dataRetur?.status === 'CANCELED') {
          if (data.key === 'approved_item_qty' || data.key === 'subtotal_approved') {
            isPush = false
          }
        }
        if (this.dataRetur?.status === 'ACCEPTED' || this.dataRetur?.status === 'REJECTED' || this.dataRetur?.status === 'CANCELED') {
          if (data.key === 'subtotal_approved') {
            isPush = false
          }
        }
        if (this.dataRetur?.status === 'ACKNOWLEDGED') {
          if (data.key === 'subtotal_approved') {
            isPush = false
          }
        }
        if (isPush) {
          finalColumns.push(data)
        }
      })
      return finalColumns
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    getLabel(label) {
      if (label === 'DRAFT') {
        return 'Draft'
      } else if (label === 'REQUESTED') {
        return 'Menunggu Konfirmasi'
      } else if (label === 'ACKNOWLEDGED') {
        return 'Dalam Proses'
      } else if (label === 'ACCEPTED') {
        return 'Selesai'
      } else if (label === 'REJECTED') {
        return 'Ditolak'
      } else if (label === 'CANCELED') {
        return 'Dibatalkan'
      } 
    },
    handleRequest(id) {
      if (this.type === 'konfirmasi') {
        this.$emit('handleConfirm', id)
      } else {
        this.$emit('handleProcess', id, this.dataApprove)
      }
    },
    showReject(param) {
      this.visibleReject = param
    },
    showUpdate(param) {
      this.visibleUpdate = param
    },
    changeReason(param) {
      this.isReason = param.target.value
    },
    handleChange(e, param) {
      const newItem = this.dataRetur.return_items.map(item => {
        const temp = {
          ...item,
          approved_item_qty: item.id === param ? (Number(e.target.value) > item.requested_item_qty ? item.requested_item_qty : Number(e.target.value)) : item.approved_item_qty,
        }
        return temp
      })
      const payload = newItem.map(item => {
        const temp = {
          id: item.id,
          approved_item_qty: item.id === param ? (Number(e.target.value) > item.requested_item_qty ? item.requested_item_qty : Number(e.target.value)) : item.approved_item_qty,
          notes_item: item.request_reason,
        }
        return temp
      })
      this.dataRetur.return_items = newItem
      this.dataApprove = payload
      console.log({e: e, param, dataRetur: this.dataRetur, newItem, payload})

    },
    numbersOnly(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    handleRejectRetur() {
      if (this.isReason.length >= 5 && this.isReason.length <= 75) {
        this.$emit('handleReject', this.dataRetur.id, this.isReason)
      }
    },
  },
}
</script>

<style lang="scss">
.modal-retur {
  .list {
  border-radius: 5px;
  }
  .header-list {
    font-weight: 900;
    background: #E7ECEF;
    border-radius: 5px ;
    padding: 10px;
  }
  .data-list {
    padding: 5px 0;
    margin: 10px 0;
    background: #fff;
    border-radius: 5px ;
  }

  // ANT DESIGN
  .ant-modal {
    width: 75% !important;
  }
  .inbound__button {
    color: #ff0854 !important;
    background: #fff !important;
    border-color: #ff0854 !important;
  }
}
.inbound__button {
    color: #ff0854 !important;
    background: #fff !important;
    border-color: #ff0854 !important;
  }

.title-tooltip {
  display: flex;
  gap: 7px;
  align-items: center;
}
.message-reason-retur {
  text-align: right;
  margin-top: 10px;
}
</style>